import { Page, StoredPagingDetails } from '../model/page.model';
import { Sort } from '../model/sort.model';
import { DataService } from '../services/data.service';
import { LoaderService } from '../loader/loader.service';
import { Subject } from 'rxjs';

export abstract class ListEvent {
    searchParams: any;
    // listName; any;
    listPage: Page = null;
    sortBy: Sort;
    totalRecords: number;
    dataSource: any[] = [];
    controllerName: string; 
    dataSourceSubject = new Subject<string>();
    storedPagingDetails: StoredPagingDetails = new StoredPagingDetails();
    restorePageState = false;    
    //dataSourceObs = this.dataSourceSub.asObservable();

    constructor(public loaderService: LoaderService, public dataService: DataService, public defaultSortField, public sortInAsc?: boolean, public listName?: string) {

    }

    private setSortBy() {
        if (this.sortBy) return;

        if (this.restorePageState && localStorage.getItem('listPagingDetails') !== null) {
            this.storedPagingDetails = JSON.parse(localStorage.getItem('listPagingDetails'));
            if (this.storedPagingDetails.listname === this.listName) {
                this.sortBy = this.storedPagingDetails.sortby;
                return;
            }
        }

        this.sortBy = new Sort(this.defaultSortField, !this.sortInAsc ? false : this.sortInAsc);
    }

    pageChanged(event) {
        this.listPage = event;
        this.setSortBy();
        this.setPagingDetails();
        this.loadDataList();
    }

    pageSizeChanged(pageSize: number) {
        this.listPage.currentPage = 1;
        this.listPage.pageSize = pageSize;
        this.setPagingDetails();
        this.loadDataList();
    }

    sortList(event) {
        this.sortBy = event;
        this.listPage.currentPage = 1;
        this.setPagingDetails();
        this.loadDataList();
    }

    displaySearchItem(event) {
        this.loadDataList();
    }

    setListDisplay(data: any) {
        this.dataSource = data.displayData;
        this.totalRecords = data.totalRecords;
    }

    resetSorting() {
        this.sortBy = new Sort(this.defaultSortField, false);
    }

    reloadData() {
        this.listPage.currentPage = 1;
        this.resetSorting();
        this.setPagingDetails();
        this.loadDataList();
    }

    private setPagingDetails() {
        if (!this.listName) return;
        this.storedPagingDetails.listname = this.listName;
        this.storedPagingDetails.listpage = this.listPage;
        this.storedPagingDetails.sortby = this.sortBy;
        localStorage.setItem('listPagingDetails', JSON.stringify(this.storedPagingDetails));
    }

    private loadDataList() {
        if (this.controllerName) {
            this.dataService.getListDataByPage(this.controllerName, this.listPage.currentPage, this.listPage.pageSize, this.searchParams, this.sortBy.header, this.sortBy.isAscOrder)
                .subscribe(data => {
                    this.setListDisplay(data);
                    this.dataSourceSubject.next(data);
                });
        }
    }

 
}
