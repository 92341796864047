export enum ApiController {
    Dropdown = "Dropdown",
    Product = "Product",
    ProductPackage = "ProductPackage",
    ProductCategory = "ProductCategory",
    Impersonation = "Impersonation",
    CustomerApplication = "CustomerApplication",
    CompletedApplication = "CompletedApplication",
    Registration = "Registration",
    AdminUser = "AdminUser",
    Agent = "Agent",
    User = "User",
    AgentPocket = "AgentPocket",
    Commission = "Commission",
    Dashboard = "Dashboard",
    Download = "Download",
    Announcement = "Announcement",
    Communication = "Communication",
    WithdrawalView = "WithdrawalView",
    WithdrawalSubmit = "WithdrawalSubmit",
    Clawback = "Clawback",
    Incentives = "Incentives",
    IncentivesUpload = "IncentivesUpload",
    LoginBanner = "LoginBanner",
    Password = "Password",
    ReportAgentSubmission = "ReportAgentSubmission",
    ReportAgentWithdrawal = "ReportAgentWithdrawal",
    ReportDuplicateCustomer = "ReportDuplicateCustomer",
    Token = "token",
}
