﻿import { Component, EventEmitter, Input, Output, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getFileNameFromResponseContentDisposition, saveFile } from 'src/app/services/file-download';
import { DataService } from '../../services/data.service';
import { saveAs } from 'file-saver';
import { forEach } from '@angular/router/src/utils/collection';
@Component({
    selector: 'file-uploader',
    templateUrl: './file-uploader.html',
    styleUrls: ['./file-uploader.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploader),
            multi: true
        }
    ]
})

export class FileUploader implements ControlValueAccessor {
    uploadedFiles: any[] = [];
    onEdit: boolean;
    @Input() readOnly: boolean = false;
    @Input() fileUrl: string;
    @Output() propagateChange: any = () => { };
    overLimitFiles: any[] = [];
    totalUploadedFiles: number = 0;
    constructor(private el: ElementRef, private dataService: DataService) { }


    clear() {
        this.uploadedFiles = null;
        this.propagateChange(null);
    }

    writeValue(files: any): void {
        if (!files) return;
        this.uploadedFiles = files;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    setChangedValue(value) {
        this.propagateChange(value);
    }

    uploadFile(files: any[])
    {
        if (files.length === 0) return;

        this.overLimitFiles = [];
        Array.from(files).forEach(file => {
            let fileInMb = this.convertSizeToMb(file.size);
            if (fileInMb <= 2) {
                this.uploadedFiles.push(file);
            }
            else {
                this.overLimitFiles.push(file);
            }
        });
        this.totalUploadedFiles = this.uploadedFiles.length;
        this.propagateChange(this.uploadedFiles);
    }

    removeFile(fileNo: number) {
        this.uploadedFiles[fileNo].deleted = true;
        this.totalUploadedFiles = this.uploadedFiles.filter(f => !f.deleted).length;
        this.propagateChange(this.uploadedFiles);
    }

    getFileSize(filesize) {
        return this.convertSizeToMb(filesize).toFixed(2);
    }

    private convertSizeToMb(filesize) {
        return filesize / (1024 * 1024);
    }

    registerOnTouched() { }
    setDisabledState?() { }

}

