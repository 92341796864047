export enum DataDisplayType {
    text,
    badge,
    tick,
    date
}

export enum ControlType {
    label,
    date,
    textbox,
    textarea,
    select,
    checkbox,
    editabledropdown,
    cascadeDropdown,
    dateRange,
    password,
    number,
    customerFinder,
    richTextEditor
}

export enum CustomerSearchType {
    completed,
    commissionClaimed
}

export enum CommissionTableDisplay {
    currentAgent,
    allAgents
}

export enum CustomerOrderDataGroup {
    application,
    orderInfo
}