
 
export class ViewOrderColumns {

  public static fields = [
    {
        "fieldName": "applicationId",
        "headerText": "Id",
        "displayType": "text",
        "keyField": true,
        "colWidth": "cell-width-1"
      },
      {
          "fieldName": "agent",
          "headerText": "Agent",
          "displayType": "text",
          "keyField": false,
          "colWidth": "cell-width-5"
      },
      {
        "fieldName": "customerName",
        "headerText": "Customer Name",
        "displayType": "text",
        "keyField": false,
        "colWidth": "cell-width-15"
      },
      {
          "fieldName": "companyName",
          "headerText": "Company Name",
          "displayType": "text",
          "keyField": false,
          "colWidth": "cell-width-10"
      },
      {
          "fieldName": "packageName",
          "headerText": "Product Package",
          "displayType": "text",
          "keyField": false,
          "colWidth": "cell-width-15"
      },
      {
          "fieldName": "category",
          "headerText": "Category",
          "displayType": "text",
          "keyField": false,
          "colWidth": "cell-width-15"
      },
      {
          "fieldName": "submittedOn",
          "headerText": "Submitted On",
          "displayType": "text",
          "keyField": false,
          "colWidth": "cell-width-5"
      },
      {
          "fieldName": "status",
          "headerText": "Status",
          "displayType": "badge",
          "keyField": false,
          "colWidth": ""
      },
      {
        "fieldName": "userId",
        "headerText": "User Id",
        "displayType": "text",
        "keyField": false,
        "colWidth": ""
      },
      {
          "fieldName": "orderNo",
          "headerText": "Order No",
          "displayType": "text",
          "keyField": false,
          "colWidth": ""
      },	
      {
          "fieldName": "residentialName",
          "headerText": "Residential Name",
          "displayType": "text",
          "keyField": false,
          "colWidth": "cell-width-15"
      }, 
      {
        "fieldName": "customerRemarks",
        "headerText": "Remarks",
        "displayType": "text",
        "keyField": false,
        "colWidth": "cell-width-10",
      },
      {
          "fieldName": "adminRemarks",
          "headerText": "Admin Note",
          "displayType": "text",
          "keyField": false,
          "colWidth": "cell-width-10",
          "adminField":true
      }
  ]

}
