import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  blackListUrl: string[] ;
  constructor(private loaderService: LoaderService) {
    this.blackListUrl = ['/api/Dropdown'];
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isUrlBlacklisted(req.url)) {
      this.showLoader();
    }
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            if (!this.isUrlBlacklisted(event.url)) {
                this.onEnd();
            }
      }
    },
      (err: any) => {
        this.onEnd();
      }));
    
  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }

  private isUrlBlacklisted(url: string): boolean {
    if (this.blackListUrl.some(bl => url.indexOf(bl) >= 0)) {
      return true;
    }
    else {
      return false;
    }
  }
}
