﻿import { Component, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

@Component({
    selector: 'back-button',
    template: `<button type="button" class="btn btn-default mrg10L" (click)="backToPrev()" >Cancel</button>`
})

export class BackButton {
    _backToPath: string;
    @Input() restorePageState: boolean = false;

    @Input() set backToPath(value: string) {
        this._backToPath = `/${value}`;
    }

    constructor(private location: Location, private router: Router) {
    }

    backToPrev() {
        if (!this._backToPath) {
            if (this.location.path)
                this.location.back();
        }
        else
        {
            let navigationExtras: NavigationExtras = {
                queryParams: {
                    'restorePageState': this.restorePageState
                }
            };

            this.router.navigate([this._backToPath], navigationExtras);
        }
    }
}
