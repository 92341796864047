import { Sort } from "./sort.model";

export class Page
{
    public pageSize: number;
    public currentPage: number;
    public totalPages: number;
    public startPage: number;
    public endPage: number;
    public restorePageState: boolean;
}

export class StoredPagingDetails {
    public listname: string;
    public listpage: Page;
    public sortby: Sort; 
}
