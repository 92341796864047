export class Sort
{

  public header: string;
  public isAscOrder: boolean;


  constructor(_header, _isAscOrder) {
    this.header = _header;
    this.isAscOrder = _isAscOrder;
  }
}
