import { NgModule } from '@angular/core';
import {
  //MatAutocompleteModule,
  //MatBadgeModule,
  //MatBottomSheetModule,
  //MatButtonModule,
  //MatButtonToggleModule,
  //MatCardModule,
  //MatCheckboxModule,
  //MatChipsModule,
  //MatDatepickerModule,
  //MatDialogModule,
  //MatDividerModule,
  //MatExpansionModule,
  //MatFormFieldModule,
  //MatGridListModule,
  //MatIconModule,
  //MatInputModule,
  //MatListModule,
  //MatMenuModule,
  //MatNativeDateModule,
  //MatPaginatorModule,
  //MatProgressBarModule,
  //MatProgressSpinnerModule,
  //MatRadioModule,
  //MatRippleModule,
  //MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  //MatSnackBarModule,
  //MatSortModule,
  //MatStepperModule,
  //MatTableModule,
  //MatTabsModule,
  //MatToolbarModule,
  //MatTooltipModule,
  //MatTreeModule,
} from '@angular/material';


@NgModule({
  exports: [

    //MatAutocompleteModule,
    //MatBadgeModule,
    //MatBottomSheetModule,
    //MatButtonModule,
    //MatButtonToggleModule,
    //MatCardModule,
    //MatCheckboxModule,
    //MatChipsModule,
    //MatStepperModule,
    //MatDatepickerModule,
    //MatDialogModule,
    //MatDividerModule,
    //MatExpansionModule,
    //MatFormFieldModule,
    //MatGridListModule,
    //MatIconModule,
    //MatInputModule,
    //MatListModule,
    //MatMenuModule,
    //MatNativeDateModule,
    //MatPaginatorModule,
    //MatProgressBarModule,
    //MatProgressSpinnerModule,
    //MatRadioModule,
    //MatRippleModule,
    //MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //MatSnackBarModule,
    //MatSortModule,
    //MatTableModule,
    //MatTabsModule,
    //MatToolbarModule,
    //MatTooltipModule,
    //MatTreeModule,
  ]
})

export class MaterialModule {

}
